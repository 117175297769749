.sp-nav-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0 -1rem 0 -1rem;
  padding-left: 0;

  li {
    padding: 0 1rem;
  }
}

.account-nav {
	height: auto;
	min-height: 3.1rem;
	max-height: 3.1rem;
	overflow: hidden;
	position: relative;
	transition: max-height 1s ease-out;
	.current-text, .toggle-arrow {
		position: absolute;
	}
	.current-text {
		top: 0;
		left: 0;
		right: 3.1rem;
		height: 3.1rem;
		color: $green-1;
		padding-bottom: 1rem;
		padding-top: 1rem;
		text-transform: uppercase;
		font-weight: 700;
		@extend .sp-label;
		opacity: 1;
		transition: opacity 1s;
		padding-left: 18px;
	}
	.toggle-arrow {
		right: 0;
		top: 0;
		width: 3.1rem;
		height: 3.1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		&:after {
			content: "";
			background: url('https://test-magento-image-repo.storage.googleapis.com/toggle_arrow.svg') center center / contain no-repeat;
			width: 20px;
			height: 10px;
			color: $green-1;
			transform: rotateX(0);
		    transition: all .7s ease-out;
		}
	}
	&.active {
		max-height: 20rem;
		.toggle-arrow {
			&:after {
				transform: rotateX(180deg);
			}
		}
		.item {
			opacity: 1;
		}
		.current-text {
			opacity: 0;
			pointer-events: none;
		}
	}
	.content {
		padding: 0;
	}
	.item {
		margin: 0;
		padding: 0;
		opacity: 0;
		transition: opacity 1s;
		position: relative;
		a, > strong {
			@extend .sp-label;

			border-width: 4px;
			color: $green-1;
			padding-bottom: 1rem;
			padding-top: 1rem;
			text-transform: uppercase;
		}
		&.current {
			a, strong {
  				font-weight: 700;
				border-color: $green-1;
				color: $green-1;
			}
		}
		&:last-child {
			margin-bottom: 0;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: 18px;
				right: 18px;
				border-top: 1px solid $green-1;
			}
		}
	}
	@media (min-width: $screen-md) {
		max-height: none;
		min-height: none;
		.current-text, .toggle-arrow {
			display: none;
		}
		.item {
			opacity: 1;
		}
	}
}



.abs-margin-for-blocks-and-widgets, .block, .sidebar .widget.block:not(:last-child), .sidebar .widget:not(:last-child), .block-banners, .block-banners-inline {
  margin-bottom: 0;
}
