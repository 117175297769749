.sp-tabs {
  display: flex;
  justify-content: center;
}

.sp-tab {
  background-color: $gray-2;
  border: none;
  color: $black;
  cursor: pointer;
  flex: 1;
  font-size: $font-size-label;
  padding: 1rem;
  text-align: center;
  text-transform: uppercase;

  &:first-child {
    border-bottom-left-radius: $border-radius;
    border-top-left-radius: $border-radius;
  }

  &:last-child {
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:hover {
    background-color: $gray-2;
    border: none;
    color: $black;
  }

  &.active {
    background-color: $green-1;
    color: $white;
  }

  &:active,
  &:focus {
    border: none;
  }
}
