.checkout-index-index .modal-popup .modal-inner-wrap {
    left: 50%;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    transform: translateX(-50%);
    width: 90%;
}

// Prevent subscription cancellation buttons from getting cut off on small viewports.
.account-subscription-index {
    @media(max-width: $screen__s) {
        .modal-header {
            padding-top: 1rem;
            padding-bottom: 1rem;
        }
        .modal-content {
            padding-left: 1rem;
            padding-right: 1rem;
        }
        .sp-h5 {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .sp-h6 {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
        .content {
            ul {
                margin-bottom: 1rem;
                li {
                    margin-bottom: 0.5rem;
                }
            }
        }
    }
}

.modal-header {
  position: relative;
}

.modal-popup.modal-slide {
    left: 1rem;
    right: 1rem;
}

.modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    margin: 2rem auto;
}

.modal-popup._inner-scroll {
    margin-bottom: 4rem;
}

.modal-popup._inner-scroll .modal-inner-wrap {
    border: 2px solid $green-1;
}

.modal-custom .action-close:before, .modal-popup .action-close:before, .modal-slide .action-close:before {
    color: $green-1;
}

.modal-popup.modal-slide .modal-footer {
    border-top: 0;
    padding: 0 0 30px 0;
    text-align: center;
}

.modal-content {
    position: relative;
}

.modal-popup--subscriptions {
    .modal-popup-cta {
        margin-bottom: 15px;
    }

    .modal-popup--subscriptions__close,
    .modal-popup--subscriptions__close:hover {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-image: url(https://test-magento-image-repo.storage.googleapis.com/icon-close.svg);
        width: 20px;
        height: 20px;
        display: block;
        border: none;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;
        padding: 0;
    }
}

.modal-popup .modal-inner-wrap {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
}

.modal-popup.modal-slide._inner-scroll .modal-inner-wrap {
    min-height: unset;
}

.modal-popup.modal-slide .modal-inner-wrap[class] {
    background-color: #fff;
}

// ====================================
// PDP MODAL
// ====================================
// Product Detail modal used in results screens
#pdp-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0.25);
    padding: 0 2rem;
    z-index: 100;
}

#pdp-modal {
    position: relative;
    margin: 2rem auto;
    border: 2px solid #1d5632;
}

.pdp-modal-close, .pdp-modal-close:hover {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-image: url(https://test-magento-image-repo.storage.googleapis.com/icon-close.svg);
    width: 20px;
    height: 20px;
    display: block;
    border: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}

.modal-popup {
    .modal-title {
        @extend .sp-h4;

        border: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-bottom: 0;
        text-align: center;
        text-transform: uppercase;
    }

    .modal-subtitle {
        @extend .sp-h4--long;

        display: block;
        margin-bottom: 0;
        margin-top: 0.5rem;
        padding-bottom: 0;
        text-align: center;
        text-transform: none;
    }

    .modal-footer {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 1rem 1rem 2rem 1rem;
    }

    .modal-inner-wrap {
        max-width: 640px;
        width: 90%;
    }
}

.modal-popup .action-close {
    padding: 0.25rem;
}

.modal-custom .action-close::before,
.modal-popup .action-close::before,
.modal-slide .action-close::before {
    font-size: 3rem;
    line-height: 3rem;
}
