.sp-widget {
	p {
		color: $black;
	}
}

.sp-widget--contact-address {
	margin-top: 0;
	margin-bottom: 3rem;

	@include breakpoint('md') {
		margin-top: 10rem;
	}
}

.sp-widget--contact-form {
	//margin-bottom: 8rem;

	form {
		margin-top: 3rem;
		margin-bottom: 3rem;

		@include breakpoint('md') {
			margin-top: 4rem;
			margin-bottom: 8rem;
          }
        :focus + .sp-form-label, :not(:placeholder-shown) + .sp-form-label {
            transform: translateY(-175%);
        }
	}
}

.sp-widget-line {
	margin-bottom: 15px;
	width: 100%;
	height: 2px;
	background-color: $green-1;
}

.sp-widget-address {
	margin-bottom: 30px;
	font-size: $font-size-base;
	color: $black;
}