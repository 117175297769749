.sp-content {
  .gigya-screen .gigya-error-msg,
  .gigya-screen .gigya-error-msg.gigya-error-msg-active {
    @extend .sp-form-error;
    font-weight: 400;
    margin-top: -0.5rem;
    text-align: left;
  }

  .gigya-error-msg-title,
  .gigya-error-msg-body {
    color: $red-1;
  }

  .gigya-error-msg-title {
    font-weight: 700;
  }

  .gigya-sectionHeader {
    font-weight: 700;
  }
  
  .gigya-screen .gigya-composite-control.gigya-composite-control-label.main-text, 
  .gigya-screen.portrait div.gigya-tfa .gig-tfa-container .gig-tfa-header-text {
    padding-bottom: 0;
  }
}


.gigya-screen {
  * {
    font-family: inherit !important;
  }

  .sp-gigya-error {
    &.sp-form-error {
      @extend .sp-form-error;
    }

    &.sp-mb-4 {
      @extend .sp-mb-4;
    }

    &.sp--mt-2 {
      @extend .sp--mt-2;
    }

    &.sp-mb-4 {
      @extend .sp-mb-4;
    }
  }

  .sp-gigya-link {
    margin: 0;

    &:link,
    &:visited {
      @extend .sp-p2;

      text-decoration: underline;
    }

    &.sp-mr-4 {
      @extend .sp-mr-4;
    }
  }
}

#gigya-reset-password-form {
  .gigya-sectionHeader {
    font-weight: 700;
  }
}
