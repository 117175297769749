.sp-checkout-title {
	border-bottom: 2px solid $green-1;
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
}

.opc-wrapper {
	@media (min-width: $screen-md) {
		max-width: 50%;
	}
}

.opc-summary-wrapper {
	width: 400px;
}

.opc-sidebar {
	margin-bottom: 1rem;
	margin-top: 0;

	@media (min-width: $screen-md) {
		max-width: 400px;
	}
}

.sp-form-group .message.warning {
	display: none;
}

.opc-block-summary {
	background-color: $gray-4;
	margin-bottom: 2rem;
	mix-blend-mode: multiply;

	@media (min-width: $screen-md) {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 0.5rem;
	}

	.discount {
		&.coupon {
			display: block;
		}

		.title {
			display: none;
		}
	}
}

.opc-block-summary {
	> .title {
		@extend .sp-h4;
		border-bottom: 2px solid $gray-3;
		font-weight: 400;
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		text-transform: uppercase;
	}

	h5 {
		font-size: $font-size-label;
		margin-bottom: 1rem;
		margin-top: 0;
		text-transform: uppercase;
	}

	.totals-wrapper .billing-blurb {
		font-size: 12px;
	}

	@media (max-width: ($screen-md - 1)) {
		height: auto;
		overflow: hidden;
		position: relative;
		transition: max-height 0.5s ease-out;

		.toggle-arrow {
			position: absolute;
			right: 0;
			top: 0;
			width: 5.1rem;
			height: 5.1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			&:after {
				content: "";
				background: url("https://test-magento-image-repo.storage.googleapis.com/toggle_arrow.svg")
					center center / contain no-repeat;
				width: 20px;
				height: 10px;
				color: $green-1;
				transform: rotateX(0);
				transition: all 0.5s ease-out;
			}
		}
		.totals-wrapper,
		.order-summary-products {
			max-height: 0;
			margin-bottom: 0;
			overflow: hidden;
			opacity: 0;
			transition: all 0.5s ease-out;
		}
		&.active {
			max-height: 60rem;
			.toggle-arrow {
				&:after {
					transform: rotateX(180deg);
				}
			}
			.totals-wrapper,
			.order-summary-products {
				max-height: 20rem;
				opacity: 1;
			}
			.order-summary-products {
				margin-bottom: 1.5rem;
			}
		}
	}
}

.order-summary-products {
	border-bottom: 2px solid $gray-3;
	margin-bottom: 1.5rem;
	padding-bottom: 0;

	ol {
		list-style-type: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	li {
		margin-bottom: 1.5rem;
	}

	.product {
		display: flex;
	}

	.product-image-container {
		flex: 0 0 auto;
		margin-right: 0.5rem;
		width: 3.5rem;

		.product-image-wrapper {
			height: 100%;
			width: 100%;

			img {
				margin: 8px auto 0;
			}
		}
	}

	.product-item-inner {
	}

	.product-item-details {
		flex: 1 1 auto;
	}

	.product-item-inner {
		align-items: baseline;
		display: flex;
		flex: 1 1 auto;
		font-size: $font-size-p2;
		justify-content: space-between;
	}

	.product-item-name {
		margin: 0;
	}

	.subtotal {
		flex: 0 0 auto;
		margin-left: 1rem;
		text-align: right;
	}

	.price {
		font-size: $font-size-p2;
		font-weight: 400;
	}
}

.sp-estimated-arrival {
	margin-bottom: 0;

	div {
		background-color: $gray-2;
		padding: 0.5rem;
		text-align: center;
	}
}

.totals.discount {
	color: $green-1;
}

.opc-progress-bar {
	margin: 2rem 0;
	position: relative;
	text-align: center;
	width: 100%;
}

.opc-progress-bar-item {
	width: 50%;

	&::before {
		top: 19px;
		background-color: $gray-2;
		height: 1px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	span {
		color: $gray-5;
		font-size: $font-size-label;
		font-weight: 700;
		padding-top: 2.5rem;
		text-transform: uppercase;
	}

	span::before {
		height: 10px;
		margin-left: 0;
		width: 10px;
		border-radius: 50%;
		background-color: $gray-2;
		left: 50%;
		position: absolute;
		top: 15px;
		transform: translateX(-50%);
	}

	span::after {
		content: none;
	}
}

.opc-progress-bar-item._active {
	&::before {
		top: 19px;
		content: "";
		background-color: $green-1;
		height: 2px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	span::before {
		content: none;
	}

	span::after {
		background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='33px' height='33px' viewBox='0 0 33 33' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='LawnSubx' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='M-1.1-ShippingInfo-D-1.1-ShippingInfo-Default-Annual' transform='translate(-94.000000, -129.000000)' stroke='%231D5632' stroke-width='2'%3E%3Cg id='Bread-Crumb' transform='translate(31.000000, 130.000000)'%3E%3Cg id='Check' transform='translate(64.000000, 0.000000)'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='15.5' cy='15.5' r='15.5'%3E%3C/circle%3E%3Cpolyline id='check-2' points='9.79394531 15.6806498 14.697505 20.5842095 21.7939453 9.97731393'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
		background-repeat: no-repeat;
		border-radius: 50%;
		content: "";
		font-size: 1.8rem;
		height: 33px;
		left: 50%;
		line-height: 1;
		margin-left: 0;
		position: absolute;
		top: 3px;
		transform: translateX(-50%);
		width: 33px;
		z-index: 1;
	}
}

.opc-progress-bar-item._complete {
	&::before {
		background-color: $green-1;
		height: 2px;
	}

	&::after {
		content: none;
	}

	span::before {
		background-color: $green-1;
	}

	span::after {
		content: none;
	}
}

.opc-wrapper {
	.step-title {
		border-bottom: 2px solid #1d5632;
		font-size: $font-size-h4--mobile;
		font-weight: 700;
		margin-bottom: 1.25rem;
		padding-bottom: 1.25rem;
		text-transform: uppercase;

		@media (min-width: $screen-sm) {
			font-size: $font-size-h4;
		}
	}
}

.payment-group .step-title {
	display: none;
}

.payment-method-title {
	display: none;
}

.checkout-payment-method {
	.payment-methods {
		margin: 0;
	}

	.payment-method-content {
		padding-left: 0;
	}

	.billing-address-same-as-shipping-block {
		align-items: center;
		display: flex;
		font-size: $font-size-p2;
		margin-bottom: 2rem;

		input {
			@extend .sp-checkbox;
			margin-right: 0.5rem;
		}
	}
}

.discount-code {
	display: none;
}

.opc-block-shipping-information .shipping-information-title {
	@extend .sp-checkout-title, .sp-h4;
}

.opc-block-shipping-information .ship-to,
.opc-block-shipping-information .ship-via {
	margin: 2rem 0;
}

.opc-block-shipping-information {
	padding: 0;
}

.ship-to .sp-link {
	text-decoration: none;
}

.opc-estimated-wrapper {
	display: none;
}

.actions-toolbar {
	text-align: center;

	@media (min-width: $screen-md) {
		text-align: left;
	}
}

// Success Page
.checkout-onepage-success .page-wrapper #maincontent {
	margin: 0;
}

.checkout-onepage-success .page-wrapper #maincontent .checkout-success {
	width: 100%;
}

.sp-success__app-image {
	@media (min-width: $screen-md) {
		bottom: 0;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
	}
}

.sp-success__articles {
	hr {
		border: 2px solid $green-1;
	}
}

.sp-success__article-image-container {
	overflow: hidden;
	padding-bottom: 80%;
	position: relative;
	width: 100%;
}

.sp-success__article-image {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

.sp-success__article-link {
	@extend .sp-label;

	bottom: 0;
	cursor: pointer;
	left: 0;
	padding: 1rem;
	position: absolute;
	right: 0;

	&:hover {
		color: $white;
	}
}

.sp-success__article-link-out {
	position: absolute;
	right: 1rem;
	transform: translateY(-50%);
	top: 50%;
}

.sp-summary__price {
	text-transform: uppercase;
}

.sp-table-totals {
	td,
	th {
		@extend .sp-p2;

		padding: 0.5rem 0 !important;
		vertical-align: middle !important;
	}
}

 .opc-block-summary .grand.totals {
     display: none;
 }
