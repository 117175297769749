.sp-header {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  height: $header-height;
  padding: 1rem 0;
  position: relative;
}

.sp-menu-active {
  height: 100%;
  overflow: hidden;

  @media (min-width: $screen-md) {
    overflow: auto;
  }
}

.sp-header__home-link {
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.sp-header__logo {
  height: 40px;
  margin-right: .5rem;
  width: 80px;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.sp-header__title {
  flex: 1;
}

.sp-header__nav {
  background-color: $green-1;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 6rem 4rem;
  position: fixed;
  flex: 1 1 auto;
  overflow: auto;
  text-align: center;
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  transition: transform .5s ease;
  z-index: 60;

  .sp-is-active & {
    transform: translateX(0);
  }

  a {
    text-decoration: none;
  }

  @media (min-width: $screen-md) {
    background-color: transparent;
    flex-direction: row;
    justify-content: flex-end;
    overflow: hidden;
    padding: 0;
    position: relative;
    transform: translateX(0) !important;
  }
}

.sp-header__nav-toggle {
  @extend .sp-label;

  background-color: transparent;
  border: none;
  color: $green-1;
  justify-self: flex-end;
  cursor: pointer;

  .sp-is-active & {
    color: transparent;

    &:after {
      color: $white;
      content: '\00D7';
      line-height: 2rem;
      font-size: $font-size-h2--mobile;
      font-weight: 400;
      height: 2rem;
      position: fixed;
      right: 1rem;
      top: 1rem;
      width: 2rem;
      z-index: 70;
    }
  }

  &:active,
  &:focus,
  &:hover {
    background-color: transparent;
    border: none;
  }
}

.sp-header__nav-list {
  flex-direction: column;

  @media (min-width: $screen-md) {
    color: $green-1;
    flex-direction: row;
  }

  li {
    padding-bottom: 2rem;
    text-align: center;
    width: 100%;

    @media (min-width: $screen-md) {
      margin-bottom: 0;
      padding-bottom: 0;
      width: auto;
    }
  }

  a {
    display: inline-block;
    color: $white;
    font-size: $font-size-h2--mobile;
    text-transform: none;
    transition: border .25s ease-in-out;

    @media (min-width: $screen-md) {
      color: $green-1;
      font-size: $font-size-label;
      text-transform: uppercase;
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
      color: $white;
      outline: none;

      @media (min-width: $screen-md) {
        color: $green-1;
      }
    }

    &.sp-link {
      border-bottom: 2px solid transparent;

      &.active,
      &:hover {
        border-bottom: 2px solid $white;

        @media (min-width: $screen-md) {
          border-color: $green-1;
        }

        text-decoration: none;
      }
    }
  }
}

.sp-header__nav-list--left {
  display: block;

  @media (min-width: $screen-md) {
    padding-right: 3rem;
    display: flex;
  }
}

.sp-header__nav-list--right {
  border-bottom: 2px solid $white;
  margin-bottom: 2rem;

  @media (min-width: $screen-md) {
    border-bottom: 0;
    border-left: 2px solid $green-1;
    padding-left: 1rem;
    margin-bottom: 0;
    position: relative;
    width: auto;
  }
}

.sp-login {
  margin-left: 1.5rem;
  position: relative;
  @media (max-width: $screen-md) {
    margin-left: 0;
  }
}

.sp-login::before {
  content: " ";
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='15px' height='17px' viewBox='0 0 15 17' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3EIcon-User%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Icon-User' transform='translate(1.000000, 1.000000)' stroke='%231D5632' stroke-width='2'%3E%3Cg%3E%3Ccircle id='Oval' cx='6.34615385' cy='3.17307692' r='3.17307692'%3E%3C/circle%3E%3Cpath d='M12.6923077,16 C12.6923077,12.495116 9.85103784,9.65384615 6.34615385,9.65384615 C2.84126986,9.65384615 -3.37507799e-14,12.495116 -3.37507799e-14,16' id='Oval-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-repeat: no-repeat;
  position: absolute;
  left: -1.5rem;
  bottom: 0;
  top: 0;
  width: 1rem;
}

.sp-header__address {
  color: $white;
  font-style: normal;
  padding-bottom: 2rem;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.sp-header__phone {
  color: $white;
  display: inline-block;
  margin-bottom: 2rem;
  align-self: center;
  border-bottom: 1px solid transparent;

  &:focus {
    outline: none;
  }

  @media (min-width: $screen-md) {
    display: none;
  }

  &.active,
  &:hover {
    color: $white;
    border-bottom: 1px solid $white;
    text-decoration: none;
  }
}

.sp-header__social,
.sp-header__nav-secondary {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 0;
  margin: 0;
  list-style: none;

  li {
    padding: 0 1rem 1rem 1rem;
    font-weight: 400;
  }

  a {
    color: $white;
    font-size: $font-size-label;

    &:hover {
      color: $white;
    }
  }

  @media (min-width: $screen-md) {
    display: none;
  }
}

.sp-header__social {
  margin-bottom: 1rem;
}

.sp-social-icon {
  display: block;
  height: auto;
  margin: 0 auto;
  width: 2rem;

  &:hover {
    opacity: .8;
  }
}
