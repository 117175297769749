.sp-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.sp-icon--social {
  font-size: 2rem;
}

.sp-icon--facebook {
  width: 0.587890625em;
}

.sp-icon--instagram {
  width: 0.857421875em;
}

.sp-icon--twitter {
  width: 0.9287109375em;
}
