.sp-quiz {
	height: calc(100% - #{$header-height});
}

.yourplan-options-index {
	&.sp-overflow-hidden {
		overflow: unset;
	}
}

@supports (-webkit-overflow-scrolling: touch) {
  .sp-quiz {
		@media (max-width: $screen-md) {
			height: calc(100% - #{$footer-height-mobile});
		}
  }
}