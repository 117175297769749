$colors: (
  "green-1": $green-1,
  "green-2": $green-2,
  "green-3": $green-3,
  "green-4": $green-4,
  "yellow-1": $yellow-1,
  "yellow-2": $yellow-2,
  "red-1": $red-1,
  "gray-1": $gray-1,
  "gray-2": $gray-2,
  "white": $white,
  "black": $black,
  "transparent": transparent
);

@each $name, $color in $colors {
  .sp-bg-#{$name} {
    background-color: $color;
  }

  .sp-text-#{$name} {
    color: $color;
  }
}

// Alignment
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:
    }

    .#{$size}sp-text-left {
      text-align: left;
    }
    .#{$size}sp-text-center {
      text-align: center;
    }
    .#{$size}sp-text-right {
      text-align: right;
    }
  }
}

// Background Position
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:
    }

    .#{$size}sp-bg-bottom {
      background-position: bottom;
    }
    .#{$size}sp-bg-center {
      background-position: center;
    }
    .#{$size}sp-bg-left {
      background-position: left;
    }
    .#{$size}sp-bg-left-bottom {
      background-position: left bottom;
    }
    .#{$size}sp-bg-left-top {
      background-position: left top;
    }
    .#{$size}sp-bg-right {
      background-position: right;
    }
    .#{$size}sp-bg-right-bottom {
      background-position: right bottom;
    }
    .#{$size}sp-bg-right-top {
      background-position: right top;
    }
    .#{$size}sp-bg-top {
      background-position: top;
    }
  }
}


// Border Radius
.sp-rounded {
  border-radius: $border-radius;
}

// Border Radius
.sp-rounded-full {
  border-radius: 100%;
}

// Display
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-block {
      display: block;
    }
    .#{$size}sp-inline-block {
      display: inline-block;
    }
    .#{$size}sp-flex {
      display: flex;
    }
    .#{$size}sp-inline-flex {
      display: inline-flex;
    }
    .#{$size}sp-hide {
      display: none;
    }
  }
}

.sp-hidden {
  clip: rect(1px, 1px, 1px, 1px); /* IE */
  clip-path: polygon(0 0, 0 0, 0 0);
  height: 1px;
  overflow: hidden !important;
  position: absolute;
  width: 1px;
}

.sp-nobr {
  white-space: nowrap;
}

// Flex
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-flex-auto {
      flex: 1 1 auto;
    }

    .#{$size}sp-flex-no-shrink {
      flex-shrink: 0;
    }

    .#{$size}sp-flex-none {
      flex: none;
    }

    .#{$size}sp-flex-wrap {
      flex-wrap: wrap;
    }

    .#{$size}sp-flex-no-wrap {
      flex-wrap: nowrap;
    }

    .#{$size}sp-flex-row {
      flex-direction: row;
    }

    .#{$size}sp-flex-column {
      flex-direction: column;
    }

    .#{$size}sp-items-center {
        align-items: center;
    }

    .#{$size}sp-items-end {
      align-items: flex-end;
    }

    .#{$size}sp-items-start {
      align-items: flex-start;
    }

    .#{$size}sp-items-baseline {
      align-items: baseline;
    }
    .#{$size}sp-justify-between {
      justify-content: space-between;
    }
    .#{$size}sp-justify-around {
      justify-content: space-around;
    }
    .#{$size}sp-justify-center {
      justify-content: center;
    }
    .#{$size}sp-justify-start {
      justify-content: flex-start;
    }
    .#{$size}sp-justify-end {
      justify-content: flex-end;
    }
  }
}

// Font weights
.sp-font-bold {
  font-weight: 700;
}

.sp-font-normal {
  font-weight: 400;
}

// Height
.sp-h-full {
  height: 100%;
}

// Full height, minus header
.sp-h-full--header {
  height: calc(100vh - #{$header-height});
}

// List
.sp-list-none {
  list-style-type: none;
}

// Order
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-order-0 {
      order: 0;
    }

    .#{$size}sp-order-1 {
      order: 1;
    }

    .#{$size}sp-order-2 {
      order: 2;
    }

    .#{$size}sp-order-3 {
      order: 3;
    }

    .#{$size}sp-order-4 {
      order: 4;
    }

    .#{$size}sp-order-5 {
      order: 5;
    }
  }
}

// Overflow
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-overflow-auto {
      overflow: auto;
    }

    .#{$size}sp-overflow-hidden {
      overflow: hidden;
    }
  }
}

// Spacing
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-mx-auto {
      margin-left: auto;
      margin-right: auto;
    }

    @each $key, $value in ("": null, "x": null, "y": null, "t": "top", "b": "bottom", "l": "left", "r": "right") {
      @each $increment in $spacing {
        @if $key == "x" {
          .#{$size}sp-m#{$key}-#{$increment} {
            margin-left: spacing($increment);
            margin-right: spacing($increment);
          }

          .#{$size}sp--m#{$key}-#{$increment} {
            margin-left: spacing(-$increment);
            margin-right: spacing(-$increment);
          }

          .#{$size}sp-p#{$key}-#{$increment} {
            padding-left: spacing($increment);
            padding-right: spacing($increment);
          }
        } @else if $key == "y" {
          .#{$size}sp-m#{$key}-#{$increment} {
            margin-bottom: spacing($increment);
            margin-top: spacing($increment);
          }

          .#{$size}sp--m#{$key}-#{$increment} {
            margin-bottom: spacing(-$increment);
            margin-top: spacing(-$increment);
          }

          .#{$size}sp-p#{$key}-#{$increment} {
            padding-bottom: spacing($increment);
            padding-top: spacing($increment);
          }
        } @else if $value == null {
          .#{$size}sp-m#{$key}-#{$increment} {
            margin: spacing($increment);
          }

          .#{$size}sp--m#{$key}-#{$increment} {
            margin: spacing(-$increment);
          }

          .#{$size}sp-p#{$key}-#{$increment} {
            padding: spacing($increment);
          }
        } @else {
          .#{$size}sp-m#{$key}-#{$increment} {
            margin-#{$value}: spacing($increment);
          }

          .#{$size}sp--m#{$key}-#{$increment} {
            margin-#{$value}: spacing(-$increment);
          }

          .#{$size}sp-p#{$key}-#{$increment} {
            padding-#{$value}: spacing($increment);
          }
        }
      }
    }
  }
}

// Position
.sp-absolute {
  position: absolute;
}

.sp-fixed {
  position: fixed;
}

.sp-relative {
  position: relative;
}

.sp-static {
  position: static;
}

// Shadows
.sp-shadow {
  box-shadow: $shadow;
}

.sp-shadow-md {
  box-shadow: $shadow-md;
}

.sp-shadow-lg {
  box-shadow: $shadow-lg;
}

.sp-shadow-xl {
  box-shadow: $shadow-xl;
}

.sp-shadow-inner {
  box-shadow: $shadow-inner;
}

.sp-max-w-xs {
  max-width: 320px;
}

.sp-max-w-sm {
  max-width: 480px;
}

.sp-max-w-md {
  max-width: 640px;
}

.sp-max-w-lg {
  max-width: 768px;
}

.sp-max-w-xl {
  max-width: 1024px;
}

// Text Transform
.sp-uppercase {
  text-transform: uppercase;
}

.sp-lowercase {
  text-transform: lowercase;
}

// Widths
@each $size in $breakpoints {
  @include breakpoint($size) {
    @if $size {
      $size: #{$size}\:;
    }

    .#{$size}sp-w-full {
      width: 100%;
    }

    .#{$size}sp-w-1\/2 {
      width: 1/2 * 100%;
    }

    .#{$size}sp-w-1\/3 {
      width: 1/3 * 100%;
    }

    .#{$size}sp-w-2\/3 {
      width: 2/3 * 100%;
    }

    .#{$size}sp-w-1\/4 {
      width: 1/4 * 100%;
    }

    .#{$size}sp-w-3\/4 {
      width: 3/4 * 100%;
    }

    .#{$size}sp-w-1\/5 {
      width: 1/5 * 100%;
    }

    .#{$size}sp-w-2\/5 {
      width: 2/5 * 100%;
    }

    .#{$size}sp-w-3\/5 {
      width: 3/5 * 100%;
    }

    .#{$size}sp-w-4\/5 {
      width: 3/5 * 100%;
    }

    .#{$size}sp-w-1\/6 {
      width: 1/6 * 100%;
    }

    .#{$size}sp-w-5\/6 {
      width: 5/6 * 100%;
    }

    .#{$size}sp-w-1\/8 {
      width: 1/8 * 100%;
    }

    .#{$size}sp-w-7\/8 {
      width: 7/8 * 100%;
    }

    .#{$size}sp-w-1\/12 {
      width: 1/12 * 100%;
    }

    .#{$size}sp-w-5\/12 {
      width: 5/12 * 100%;
    }

    .#{$size}sp-w-7\/12 {
      width: 7/12 * 100%;
    }

    .#{$size}sp-w-11\/12 {
      width: 11/12 * 100%;
    }
  }
}

// Z-Index
.sp-z-0 {
  z-index: 0;
}

.sp-z-1 {
  z-index: 10;
}

.sp-z-2 {
  z-index: 20;
}

.sp-z-3 {
  z-index: 30;
}

.sp-z-4 {
  z-index: 40;
}

.sp-z-5 {
  z-index: 50;
}
