.page-main {
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: 100%;
}

.columns {
  display: block;
}
